<template>
  <div>
    <sm-editable-item
      v-model="form"
      :controllerName="controllerName"
      :isLoading="item.isLoading"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :dependentLinks="dependentLinks"
      :fields="fields"
      :disabledSaveButton="isEqual"
      :isLoadingSaveButton="isLoadingSaveButton"
      @save="onSave('Customers')"
      @cancel="onCancel('Customers')"
    >
      <template #createDbButton>
        <sm-button
          class="customer-action-button"
          :isLoading="isLoadingDbButton"
          @click="onOpenCreateDbModal"
        >
          Создать базу
        </sm-button>
      </template>
      <template #additionalButtons>
        <div class="customer-additional-buttons">
          <sm-button
            class="customer-action-button"
            :isLoading="isLoadingDbButton"
            @click="onOpenCreateDemoDbModal"
          >
            Создать демо-базу
          </sm-button>
          <sm-button
            class="stop-cooperation-button"
            :isLoading="isLoadingStopCooperationButton"
            :disabled="!form.enableTerminationButton"
            @click="openStopCooperationModal"
          >
            Прекратить сотрудничество
          </sm-button>
        </div>
      </template>      
    </sm-editable-item>
    <sm-form-modal
      v-model="modalForm"
      :fields="modalFields"
      :show="showCreateDbModal"
      :modal-title="modalCreateDbTitle"
      :disabledSaveButton="!modalForm.databaseName"
      :isLoadingSaveButton="isLoadingDbButton"
      @close="onCancelCreateDb"
      @save="onConfirmCreateDb"
      @cancel="onCancelCreateDb"
    />
    <sm-form-modal
      v-model="modalDemoForm"
      :fields="modalDemoFields"
      :show="showCreateDemoDbModal"
      :modal-title="modalCreateDemoDbTitle"
      :isLoadingSaveButton="isLoadingDbButton"
      :titleSaveButton="titleSaveButton"
      :showSaveButton="!modalDemoForm.demoCode"
      :showCancelButton="false"
      @close="onCancelCreateDemoDb"
      @save="onConfirmCreateDemoDb"
    >
      <template v-slot:demoCode="{ field }">
        <div v-show="modalDemoForm.demoCode" class="create-db__fields-wrap">
          <div class="create-db__field">
            <p class="editable-form__description">{{ field.label }}</p>
            <sm-input v-model="modalDemoForm.demoCode" :type="field.type" />
            <!-- :disabled="field.disabled" -->
          </div>
          <div class="create-db__field create-db__field--center">
            <sm-button
              :isLoading="isLoadingDbButton"
              class="create-db__copy-button"
              @click="onCopyDemoCode"
              >Копировать код</sm-button
            >
          </div>
        </div>
      </template>
    </sm-form-modal>
  </div>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
const { mapActions: mapActionsDbMaintenance } =
  createNamespacedHelpers('dbMaintenance');
const { mapActions: mapActionsCustomers } =
  createNamespacedHelpers('customers');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
// Utils
import { copy } from '@/utils/clipboard';

export default {
  name: 'CustomerEdit',

  components: {
    SmEditableItem,
    SmButton,
    SmFormModal,
    SmInput,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'Customers',
      pageHeader: 'Редактирование клиента',
      form: {},
      isLoadingStopCooperationButton: false,
      isLoadingDbButton: false,
      modalCreateDbTitle: 'Создание базы данных',
      modalCreateDemoDbTitle: 'Создать демо базы данных',
      showCreateDbModal: false,
      showCreateDemoDbModal: false,
      modalForm: {
        databaseName: null,
      },
      modalDemoForm: {
        databaseName: null,
        demoCode: null,
      },
      titleSaveButton: 'Создать',
      enableTerminationButton: false,
    };
  },

  computed: {
    ...mapState(['item']),

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.item.data?.name}`,
        },
      ];
    },

    dependentLinks() {
      return [
        {
          text: 'Аккаунты',
          route: {
            name: 'AccountsForCustomer',
            params: {
              id: this.id,
            },
          },
        },
        {
          text: 'Сайты клиентов',
          route: {
            name: 'SitesForCustomer',
            params: {
              id: this.id,
            },
          },
        },
        {
          text: 'Договоры',
          route: {
            name: 'OneCContractsForCustomer',
            params: {
              id: this.id,
            },
          },
        },
        {
          text: 'Контакты',
          route: {
            name: 'OneCContactsForCustomer',
            params: {
              id: this.id,
            },
          },
        },
      ];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
            // {
            //   type: 'text',
            //   key: 'folder',
            //   label: 'Папка',
            // },
            {
              type: 'text',
              key: 'createDbButton',
            },
            {
              type: 'text',
              key: 'additionalButtons',
            },
            {
              type: 'checkbox',
              key: 'allowHelpAeroAccess',
              label: 'Разрешен вход на help.1caero.ru',
            },
          ],
        },
      ];
    },

    modalFields() {
      return [
        {
          type: 'text',
          key: 'databaseName',
          label: 'Название базы данных',
        },
      ];
    },

    modalDemoFields() {
      return [
        {
          type: 'text',
          key: 'databaseName',
          label: 'Название базы данных',
        },
        {
          type: 'text',
          key: 'demoCode',
          label: 'Код демо-базы',
          disabled: true,
        },
      ];
    },
  },

  created() {
    this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });
  },

  methods: {
    ...mapActions(['getItem', 'updateItem']),

    ...mapActionsDbMaintenance(['createDatabase', 'createDemoDatabase']),

    ...mapActionsCustomers(['stopCooperation']),

    onOpenCreateDbModal() {
      this.showCreateDbModal = true;
    },

    onOpenCreateDemoDbModal() {
      this.showCreateDemoDbModal = true;
    },

    onCancelCreateDb() {
      this.showCreateDbModal = false;
      this.modalForm.databaseName = null;
    },

    onCancelCreateDemoDb() {
      this.showCreateDemoDbModal = false;
      this.modalDemoForm.demoCode = null;
    },

    onConfirmCreateDb() {
      this.isLoadingDbButton = true;
      this.createDatabase({ ...this.modalForm, customerId: this.item.data.id })
        .then(() => {
          this.modalForm.databaseName = null;
          this.showCreateDbModal = false;
        })
        .finally(() => (this.isLoadingDbButton = false));
    },

    onConfirmCreateDemoDb() {
      this.isLoadingDbButton = true;
      this.createDemoDatabase({
        customerId: this.item.data.id,
        databaseName: this.modalDemoForm.databaseName,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$notify({
              header: 'Демо база данных успешно создана',
              type: 'success',
              timer: 5000,
            });
          }

          this.modalDemoForm.demoCode = result.demoCode;
        })
        .finally(() => (this.isLoadingDbButton = false));
    },

    onCopyDemoCode() {
      copy(this.modalDemoForm.demoCode);

      this.$notify({
        header: 'Код демо-базы скопирован',
        type: 'success',
        timer: 5000,
      });
    },

    async openStopCooperationModal() {
      try {
        const itemData = this.item?.data;
        const clientName = itemData && itemData.name ? ` c ${itemData.name}` : '';
        const confirmationMessage = `Вы уверены, что хотите прекратить сотрудничество${clientName}?`;

        const confirmed = await this.showConfirmModal(confirmationMessage);
        if (!confirmed) return;

        this.isLoadingStopCooperationButton = true;

        const result = await this.stopCooperation(this.id);
        this.handleStopCooperationResult(result);

      } catch (error) {
        console.error('Ошибка при прекращении сотрудничества:', error);
        this.showNotification('Произошла ошибка при выполнении операции.', 'error');
      } finally {
        this.isLoadingStopCooperationButton = false;
      }
    },

    showConfirmModal(message) {
      return this.$root.$confirmModal({
        message,
        leftButtonText: 'Подтвердить',
        rightButtonText: 'Отменить',
      });
    },

    handleStopCooperationResult(result) {
      if (!result?.isSucceed) throw new Error();

      this.form.enableTerminationButton = false;
      this.showNotification('Сотрудничество с клиентом прекращено, все заявки клиента закрыты', 'success')
    },
    
    showNotification(header, type, text = '') {
      this.$notify({
        header,
        text,
        type,
        timer: 8000,
      });
    },

  },
};
</script>

<style lang="scss">

.customer-additional-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.stop-cooperation-button {
  width: 220px;  
}

.customer-action-button {
  width: 180px;
}

.create-db__fields-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.create-db__field {
  display: flex;
  flex-shrink: 0;
  width: 100%;
}

.create-db__field:not(:last-child) {
  margin-bottom: 15px;
}

.create-db__field--center {
  justify-content: center;
}

.create-db__copy-button {
  width: auto;
}
</style>
