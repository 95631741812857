/**
 * Копирование в буфер обмена
 * 
 * @param    {string}  content  Строка для копирования
 * 
 * @returns  {boolean}          Статус копирования
 */
export const copy = (content) => {
  const TempText = document.createElement("textarea");

  TempText.value = content;
  document.body.appendChild(TempText);
  TempText.select();

  document.execCommand("copy");
  document.body.removeChild(TempText);

  return true;
};
